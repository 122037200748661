






import { Component, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";

@Component({
    components: {
        BasePage
    },
})
export default class ContractInformation extends Vue {
}
